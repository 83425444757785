/*******************************************************************************
a simple reset
*******************************************************************************/

*,
*::after,
*::before {
  vertical-align: baseline;
  box-sizing: inherit;
  margin: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
}

html {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

svg {
  color: inherit;
  shape-rendering: geometricPrecision;
  fill: currentColor;
}
